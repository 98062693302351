// ==============================
// Override Variables
// ==============================
$global-background-color-dark: #282c35;

$header-background-color: #ececec;

$global-font-color-dark: #DDD;

@import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap');
@import url(//fonts.googleapis.com/earlyaccess/notonaskharabic.css);
$global-font-family:'Noto Naskh Arabic';
$header-title-font-family:'Cairo';
$global-font-size: 17px;